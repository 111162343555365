code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  display: flex;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
foreignObject,
svg {
  overflow: visible !important;
}

select {
  padding: 10px;
  cursor: inherit;
  background-color: white;
  font-size: 14px;
  border: 1px solid rgb(204, 204, 204);
  outline: 0px;
  width: 100%;
  opacity: 1;
  pointer-events: inherit;
}

.ReactModalPortal {
  z-index: 10;
}

.loginError {
  color: red;
  padding-bottom: 20px;
}
.react-datepicker__triangle {
  transform: translate(40px, 0px) !important;
}
.location-date .react-datepicker__input-container input {
  border: 1px solid #ccc;
  padding: 10px;
  line-height: initial;
}

.existing-location {
  padding: 10px 0 15px;
  line-height: initial;
  font: 0.875rem arial;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}
.existing-location .el-title {
  color: #888888;
  padding-bottom: 15px;
  font-weight: bold;
}
.existing-location .el-btn-panel {
  display: flex;
}
.existing-location .el-btn-panel .el-btn {
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 15px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.existing-location .el-btn-panel .el-btn.active {
  background: #019d21;
  color: #ffffff;
  font-weight: bold;
}

.title_transit {
  padding: 15px 0 5px 0;
  color: #008F2B;
  font: bold 0.875rem arial;
}
.existing-location-widthoutsave .existing-location {
  padding: 0;
  border-bottom: 0;
}
.head_transit {
  background: rgba(0, 0, 0, 0.05);
  line-height: 20px;
  font-weight: bold;
  margin: 0px !important;
  border-top: 1px solid #ddd;
}
.row_transit{
  border-top: 1px solid #ddd;
  margin: 0px !important;
}
.row_transit:last-child{
  border-bottom: 1px solid #ddd;
}
.row_transit > div {
  border-right: 1px solid #ddd;
  padding: 5px;
  box-sizing: border-box;
}
.row_transit > div:first-child{
  border-left: 1px solid #ddd;
}
.table_transit {
  font: 0.875rem arial;
  line-height: initial;
  width: 100%;
  min-width: 500px;
}
.body_transit {
  overflow-y: auto;
  max-height: 250px;
  width: 100%;
}
.btn_transit {
  width: 100%;
  background: #0089ff;
  color: #ffffff;
  border: 0;
  padding: 5px 13px;
  font: 0.75rem arial;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  box-sizing: border-box;
}
.btn_transit:hover {
  background: #494949;
}

.attachment-location-date{
  display: flex;
  align-items: center;
  padding: 10px;
  border-left:1px solid rgb(204, 204, 204);
  border-right:1px solid rgb(204, 204, 204);
}
.attachment-location-date input{
  border: 1px solid rgb(204, 204, 204);
  padding: 5px 10px;
}
.attachment-location-date .text-attachment-location{
    min-width: 120px;
    font: bold 0.875rem arial;
    color: #888888;
}

.qrCodeCurrentIntegrant{ margin-right: 10px;}
.qrCodeCurrentIntegrant canvas{ width: 100px!important; height: 100px!important; }
.download_btn_qr{padding: 5px 0;}
.download_btn_qr .btn_qr{ background: #008F2B; color: #ffffff; margin-right: 5px; border: 0; border-radius: 5px; font:bold 0.875rem arial; padding: 5px 10px;cursor: pointer;}
.download_btn_qr .btn_qr:hover{ opacity: 0.8; }
.download_btn_qr .txt_download{ font:0.875rem arial;margin-right: 10px; opacity: 1;}

.header-already-login{min-width: 300px; display: flex; align-items: center;justify-content:end;}
.text-hal-subscription{font: 0.875rem arial;padding-right: 10px;}
.header-already-login a{text-decoration: none;background: #1e7e34;color: #fff;padding: 5px 15px;text-transform: uppercase;border-radius: 5px;letter-spacing: 1px;}
.header-already-login a:hover{background: #53da00;}

@media (max-width:576px) {
  .header-already-login{
    min-width: unset;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin: auto 0px;
  }
  .attachment-location-date{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .attachment-location-date>div:nth-child(1){
    margin-bottom: 5px;
  }
}