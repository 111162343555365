/* Integrant Pane header CSS */
.integrant-page-header-div{
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: normal;
}

/* Integrant Options CSS */
.integrant-options-qr-detail-div{
    display: flex;
    align-items: center;
}

.download_btn_qr>div{
    display: inline-block;
}

/* Layer Pane CSS */
.layerpane{
    width: 100%;
    max-width: 20%;
    min-width: 20%;
    height: auto;
    min-height: 100%;
    background: #f9f9f9;
    z-index: 1;
    border-right: 1px solid #ccc;
}

.renderLayer> img{
    padding-right: 10px;
    width: 40px !important;
    height: 30px !important;
}

/* Integrant Graph CSS */
.integrantGraph{
    max-width: 40%;
    min-width: 40%;
}

.d3Graph>div{
    width: 100%;
    height: 100%;
}

/* Integrant Pane CSS */
.integrantPane{
    height: 100%;
    width: 100%;
    max-width: 40%;
    min-width: 40%;
    border-left: 1px solid #ccc;
    background-color: white;
}

/* Integrant Batch location CSS */
.integrantLocation{
    flex: 1 !important;
}

@media (max-width:1024px) {
    .batchview{
        flex-direction: column !important;
    }
    .layerpane{
        max-width: 100%;
        min-height: max-content;
        padding: 20px;
        border-right: none;
    }
    .integrantGraph{
        max-width: 100%;
        min-height: 400px !important;
    }
    .integrantPane{
        max-width: 100%;
        border-left: none;
    }
    .integrantPane > hr{
        display: none !important;
    }
    .integrantDetails{
        padding: 0px 10px;
    }
    .integrantPaneHeader{
        background-color: rgb(249, 249, 249);
    }
    .integrant-page-header-div>h5{
        white-space: nowrap;
    }
    .integrantPaneFooter{
        margin-top: 20px;
    }
}

@media (max-width:768px) {
    
}

@media (max-width:576px) {
    .integrant-options-qr-detail-div{
        flex-direction: column;
        justify-content: center;
    }
    .integrantAlert>div>small{
        white-space: unset !important;
    }
    .locationSCZRootDiv{
        flex-direction: column !important;
    }
}