.main-header{box-shadow: #32325d1a 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;background: #F9F9F9;z-index: 6;}
.brand-logo{padding: 10px 0;display: block;}

.navbar-nav{flex-direction: row;display: flex;}
.navbar-nav .nav-link{display: flex;align-items: center;padding:0 15px;text-decoration: none;color: #000;cursor: pointer;color: rgba(0, 0, 0, 0.8);font-weight: bold;border-left: 1px solid rgba(0, 0, 0, 0.1);line-height: initial; font-size: 0.9375em;}
.navbar-nav .nav-link:hover{opacity: 0.7;}
.navbar-nav li.nav-item{position: relative;}
.navbar-nav li.nav-item:first-child a.nav-link{border-left: 0;}
.navbar-nav li.nav-item.last .nav-link{padding-right: 0;}

/* .navbar-nav li:last-child.nav-item{border-left: 1px solid rgba(0, 0, 0, 0.07);} */
.navbar-nav .txt-name{padding: 0 10px;}


.navbar-nav .dropdown-menu{position:absolute;background: #ffffff;box-shadow: 0 1px 2px #d4dde7, 0 3px 9px 0px #d4dde780;min-width: 220px;right: 0;top:60px;border-radius: 5px;;padding: 10px 0;display: none;}
.nav-item{padding: 22px 0;}
.dropdown-divider{ height: 1px; background:#E9EEF3;}
.dropdown-item{padding: 8px 15px;display: block;text-decoration: none;color:#000;cursor: pointer;}
.dropdown-item:hover{background: #E9EEF3;}
.nav-item:hover > .dropdown-menu{display: block;}

#root ~ iframe{display: none;}
.page-404{width:100%;}
.text-center{text-align: center;}


.page-title{font-size: 1.25rem; padding: 0 0 10px;border-bottom: 1px solid rgba(0, 0, 0, 0.1);margin-bottom: 10px;line-height: initial;}
.page-title.prod-list{margin:0 10px;}

.threshold-Container{background: rgba(0, 0, 0, 0.7);position: fixed; width: 100%;height: 100%;z-index: 10;top:0;left: 0;display: flex;align-items: center;justify-content: center;}
.threshold-popup{background: #fff;width: 100%;max-width: 50%;padding: 20px;min-width:300px;border-radius: 10px;position: relative;}
.threshold-popup .threshold-title{font-weight: bold;}
.threshold-popup .threshold-body{padding: 10px 0 20px;}
.threshold-popup .manage-link{background: rgb(0, 137, 255);color:#ffffff;padding: 13px 20px; display: inline-block;text-decoration: none;border-radius: 5px;text-transform: uppercase;font: 0.875rem arial;}
.threshold-popup .manage-link:hover{background: rgb(2, 94, 175);}
.threshold-popup .threshold-popup-close{position: absolute; top: -10px;right:10px;transform: rotate(45deg);cursor: pointer;font-size: 2.5rem;}
.threshold-popup .threshold-popup-close:hover{opacity: 0.5;}

.transferred-section{overflow: hidden;position: relative;width: 100%;}
.transferred-list .txt-update{padding-top: 5px;}

.transferred-list-leftview{padding: 10px 10px 0;}
.transferred-list-leftview > div > div{height: calc(100vh - 200px)!important;overflow-y: auto!important;margin: 10px 0 0;}

.transferred-list{padding: 15px 10px;}
.transferred-list > div > div{overflow-y: auto!important;height: calc(100vh - 210px)!important;}
.transferred-section .body_transit{max-height: calc(100vh - 280px);background: #fff;}
.btn-close{position:absolute;top:10px;right: 10px;font-size: 2rem;line-height: 14px;height: 22px; cursor: pointer; transform: rotate(45deg);color: #000;
    font-size: 2.5rem;}
.batch-stamp{width: 25px; height: 25px; background: rgba(0, 0, 0, 0.1); border-radius: 100%; text-align: center; margin-right: 10px; font: bold 12px/25px arial;}

button:disabled {opacity: 0.5;}
.description-label{background: #efefef; font: bold 0.75rem arial; padding: 5px 10px; display: inline-block; text-transform: capitalize;}

.input-helper-text{
    font-size: 12px;
    color: red;
}
.asterisk-symbol{
    margin-left: 5px;
    color: red;
}
.listView-helper-text{
    color: #999;
    text-align: center;
    font-size: 14px;
    padding: 5px 0;
}