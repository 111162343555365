:root{
    --greenColor:#008F2B;
    --whiteColor: #fff;
    --color888: #888;
    --borderColor: rgba(0, 0, 0, 0.1);
}
/* Mui Button CSS */
.mui-btn-root{
    background-color: var(--greenColor) !important;
    color: var(--whiteColor);
    border-radius: 5px;
    padding: 6px 10px;
    text-transform: capitalize;
    cursor: pointer;
    border: none;
    width: 70px;
}
.mui-btn-root:hover{
    opacity: 0.8;
}
/* Dialogs CSS */
.dialog-title{
    border-bottom: 1px solid var(--borderColor);
}
.dialog-actions{
    padding: 16px 24px !important;
    border-top: 1px solid var(--borderColor);
}
.dialog-actions>button:nth-child(2){
    margin-left: 10px;
}