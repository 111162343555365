._mb0 {
  margin-bottom: 0;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.subscription-page {
  width: 100%;
  background: #ffffff;
}

.subscription-page .header-plans {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 15px;
  /* background: #f9f9f9; */
  text-align: center;
  width: 80%;
  margin: auto;
}
.title01-subscription {
  font-size: 2.25rem;
  color: #000000;
  line-height: initial;
  text-transform: uppercase;
  font-weight: 700;
}
.title02-subscription {
  font-size: 1rem;
  padding-top: 5px;
  text-transform: uppercase;
  font-weight: 700;
}
.box-already-login {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  align-items: center;
  justify-content: center;
  box-shadow: rgb(50 50 93 / 10%) 1px -4px 14px 0px, rgb(0 0 0 / 7%) 0px 3px 6px 0px;
}
.box-already-login a {
  text-decoration: none;
  color: #000000;
  background: #1e7e34;
  color: #ffffff;
  padding: 5px;
  display: block;
  border-radius: 5px;
  min-width: 80px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 10px;
}
.box-already-login a:hover {
  background: #53da00;
}
.no-sc .no-sc-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.item-sc {
  background: #f9f9f9;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
  padding: 15px 0;
  line-height: initial;
  color: #000000;
  box-shadow: inset 0 0 15px 2px rgb(32 33 36 / 28%);
}
.no-sc-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.slick-active.slick-current .item-sc {
  color: #ffffff;
}
.slick-active .name-sc {
  font: 1.875rem arial;
  text-transform: capitalize;
  font-weight: normal;
  padding-bottom: 15px;
}

.subscription-main-heading {
  font-size: 36px;
  font-weight: 700;
  line-height: 39px;
  color: #000;
  text-transform: uppercase;
}
.subscription-carousel {
  width: 100%;
  padding: 30px 0 !important;
}
.subscription-carousel > h2,
.subscription-carousel > div:nth-child(2) {
  padding: 0px 70px !important;
}
.subscription-carousel > h2 {
  margin-bottom: 30px;
}
.subscription-carousel > hr {
  margin: 0 70px 30px 70px;
  width: calc(100% - 140px);
}
.subscription-carousel > div:nth-child(4) {
  margin: 0 10px;
}
.subscription-carousel .slick-next {
  right: 50px;
  z-index: 9;
}
.subscription-carousel .slick-list {
  /* height: 420px; */
  padding: 0 !important;
  margin: 0 70px !important;
}
.subscription-carousel .slick-prev {
  left: 0 !important;
}
.subscription-carousel .slick-next {
  right: 20px !important;
}
.subscription-page-load {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.subscription-page-load > div {
  display: flex;
}
.subscription-page-load > div > div {
  margin-right: 10px;
}
.sign-up-duration-sc {
  background: #fff;
  display: inline-block;
  padding: 7px 20px;
  border-radius: 10px;
  text-decoration: none;
  color: #000;
}
.curr-subscription ~ .sign-up-duration-sc.s_1 {
  display: none;
}
.price-sc {
  padding: 5px 0;
  margin: 5px 0;
  display: flex;
  align-items: end;
  justify-content: center;
}
.price-sc .price-duration-sc {
  font: 0.875rem arial;
  margin: 0 0 3px 0;
}
.price-sc .price-charges-sc {
  font-size: 1.5rem;
  font-weight: bold;
}
.layout-duration-sc {
  padding: 0 0 10px;
  opacity: 0.8;
}
.sign-up-duration-sc:hover {
  background: #d8d7d7;
}
.subscriptionCallMe {
  padding: 25px 0 5px;
}
.subscriptionCallMe a {
  color: #ffffff;
  text-decoration: none;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.subscriptionCallMe a:hover {
  text-decoration: underline;
}
.subscriptionCallMe + .subscriptionCallMe {
  display: none;
}
.subscriptionCallMe + .subscriptionCallMe + .layout-duration-sc + .sign-up-duration-sc {
  display: none;
}

.sLayoutRootDiv {
  width: 100%;
  background: #f1f3f6;
}
.sLayout {
  background: #f1f3f6;
  width: 100%;
  padding-top: 16px;
  position: relative;
}
.sSection {
  background: #ffffff;
  margin-bottom: 16px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
  border-radius: 0 0 5px 5px;
}
.s_title {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.s_title .s_sno {
  background: #f0f0f0;
  display: block;
  padding: 1px 7px;
  font: 0.75rem/18px arial;
  min-width: 20px;
  text-align: center;
  margin-right: 10px;
  border-radius: 3px;
  color: #000;
  border-radius: 100%;
}
.s_title .s_text {
  text-transform: uppercase;
  font: bold 1rem arial;
  letter-spacing: 1px;
  line-height: 23px;
  color: #878787;
}
.s_title .s_text svg {
  margin-left: 10px;
  fill: #008f2b;
}
.sSection.active .s_title {
  background: #008f2b;
}
.sSection.active .s_title .s_text {
  color: #fff;
}
.sSection.active .s_title span:not(.btn_done) {
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.sSection .s_title .btn_done {
  border: 1px solid #008f2b;
  display: inline-block;
  border-radius: 0;
  padding: 0 10px;
  font: 0.875rem arial;
  padding: 3px 10px;
  color: #000000;
}
.sSection .s_title .btn_change {
  padding: 10px 32px;
  font: bold 0.875rem arial;
  border: 1px solid #e0e0e0;
  color: #008f2b;
  display: block;
  letter-spacing: 1px;
  cursor: pointer;
}
.sSection .s_title .btn_change:hover {
  background: #e0e0e0;
}
.s_title div {
  display: flex;
  width: 100%;
  align-items: center;
}

.btn_registration_submit {
  border: 0;
  padding: 7px 20px;
  text-transform: uppercase;
  margin-top: 16px;
  letter-spacing: 1px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.btn_registration_submit:not([disabled]) {
  background: #1e7e34;
  color: #ffffff;
  cursor: pointer;
}
.btn_registration_submit.no {
  margin-top: 0;
  margin-bottom: 0;
}
.section-pre-registration-form .btn_registration_submit:not([disabled]):hover {
  background: #304a38;
}
.section-pre-registration-form .col-form-label {
  padding-top: 20px;
  display: block;
  padding-bottom: 5px;
}
.section-pre-registration-form .col-form-label span {
  color: red;
}

.error-message {
  color: red;
  font: 0.75rem arial;
  padding-top: 5px;
}

.service-agreement {
  padding: 20px 20px 0;
}
.service-agreement-tc {
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 5px;
  max-height: 30vh;
  overflow-x: auto;
  min-height: 100px;
  font: 0.875rem/22px Arial, Helvetica, sans-serif;
}

.service-agreement-tc h1{
  color: #008f2b;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.service-agreement-tc p{
  margin-bottom: 10px;
}

.strip-tc {
  padding: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.strip-tc input {
  width: initial !important;
  margin-right: 10px !important;
}
.strip-tc .strip-tc-text {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
}

.payment-options {
  padding: 10px 16px;
}
.section-pre-registration-form {
  padding: 0 16px;
}

.sSection .base-header {
  padding: 12px 15px;
  margin-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font: bold 1rem/23px arial;
  color: #878787;
}
.coupons-base .coupons-base-content {
  padding: 0 15px;
}
.couponsForm-base-check {
  padding: 15px;
}
.couponsForm-base-check button {
  cursor: pointer;
  border: 0;
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
}
.couponsForm-base-check button:not([disabled]) {
  background: #1e7e34;
  color: #ffffff;
}
.coupon-failed {
  font: 0.875rem arial;
  color: red;
}
.coupon-failed:not(:empty) {
  margin-top: 10px;
}

.item-cart {
  padding: 0 15px 15px;
}
.item-cart-name {
  font-weight: bold;
}
.item-cart ul {
  font: 0.875rem arial;
  margin: 0;
  padding: 0;
}
.item-cart li {
  margin-left: 20px;
  padding: 3px 0;
}
.item-cart-type {
  padding: 10px 0 0;
  font-weight: bold;
  font-size: 0.875rem;
}
.price-list {
  font: 0.875rem arial;
  margin-top: 10px;
}
.price-list > div {
  display: flex;
  align-items: center;
}
.price-list input {
  margin-right: 10px !important;
}
.item-cart-amount {
  border: 1px dashed #aaaaaa;
  border-left: 0;
  border-right: 0;
  margin: 21px 0 0;
  padding: 12px 0;
  font: bold 1rem arial;
}

.link-order-plans {
  display: block;
  background: #494949;
  margin: 0 0 6px 16px;
  color: #ffffff;
  font: 0.75rem arial;
  text-decoration: none;
  max-width: 100px;
  text-align: center;
  padding: 5px 0;
  border-radius: 3px;
}
a.txt-service-agreement {
  text-decoration: none;
  font: 0.875rem arial;
}
a.txt-service-agreement:hover {
  text-decoration: underline;
}

.btn-browse {
  position: relative;
  background: #494949;
  display: inline-block;
  max-width: 101px;
  border-radius: 5px;
}
.btn-browse .text-btn-browse {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  width: 100%;
  text-align: center;
  line-height: 34px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}
.btn-browse input {
  opacity: 0;
  padding: 6px 0;
  cursor: pointer;
}
.list-filename {
  padding-left: 10px;
}

.subscription-checkout-load {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 0;
  color: #ffffff;
  z-index: 1;
}
.subscription-checkout-load .txt-please-wait {
  padding-top: 10px;
}

.payment-options-price {
  padding: 10px 0 20px 0;
}
.payment-options-price .plan-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 11px;
}
.payment-options-price .plan-list label {
  margin: 0 20px 10px 0;
}
.payment-options-price .plan-list label strong:before {
  padding: 0 5px;
}

.thankYou {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.thankYou a {
  background: #008f2b;
  color: #fff;
  padding: 8px 20px;
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  border-radius: 5px;
}
.thankYou a:hover {
  background: #494949;
}

#payment-message {
  font: 0.875rem arial;
  color: #df1b41;
}
.loader-payment {
  margin-right: 15px;
}
.strip-registration {
  padding: 15px 0 10px;
}
.btn_registration_submit1 {
  border: 0;
  padding: 7px 20px;
  text-transform: uppercase;
  border-radius: 5px;
  margin-right: 15px;
}
.btn_registration_submit1:not([disabled]) {
  background: #1e7e34;
  color: #ffffff;
  cursor: pointer;
}
.slick-active.slick-current .item-sc {
  background: #008f2b;
}

@media only screen and (min-width: 768px) {
  .subscription-carousel .slick-next:before,
  .subscription-carousel .slick-prev:before {
    color: #008f2b;
    font-size: 40px;
  }
  .subscription-carousel .slick-prev {
    left: 30px;
    z-index: 9;
  }
  .subscription-item-width {
    transform: scale(0.85);
    transition: all 1s;
  }
  .slick-active.slick-current .subscription-item-width {
    transform: scale(1);
  }
  .subscription-carousel {
    overflow-x: hidden;
    padding: 4vh 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .title01-subscription,
  .subscription-main-heading,
  .slick-active .name-sc {
    font-size: 2rem;
    font-weight: bold;
  }
  .title02-subscription,
  .no-sc-item {
    font-size: 0.875rem;
  }
  .price-sc .price-charges-sc {
    font-size: 1rem;
  }
  .subscription-page .header-plans {
    padding: 10px 0;
    width: 100%;
  }
  .title02-subscription {
    padding-top: 0;
  }
}

.cs {
  width: 100%;
  padding: 20px 0;
  font-family: Arial;
}
.cs .title-invoice,
.cs-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 0 10px;
}
.btn-subscription {
  text-decoration: none;
  background: #1e7e34;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  letter-spacing: 1px;
  font: 0.875rem arial;
  cursor: pointer;
}
.btn-subscription.cancel {
  background: rgb(37, 37, 37);
  margin-right: 10px;
}
.btn-subscription:not(.cancel):hover {
  background: #53da00;
}
.subscription-title {
  font-size: 1.25rem;
}

.section-plan {
  padding: 15px 0 30px;
}
.section-plan > .row {
  padding-bottom: 10px;
}

.cs .title-invoice {
  font-size: 1.125rem;
  margin-bottom: 15px;
  font-weight: bold;
}

.cs-table {
  border: 2px solid rgba(0, 0, 0, 0.1);
  min-width: 700px;
}
.cs-table-head {
  background: rgba(0, 0, 0, 0.1);
}
.cs-table-head,
.cs-table-body {
  padding: 0 10px !important;
}
.cs-table-body .row {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.cs-table-head .row > div {
  font-weight: bold;
}
.cs-table-body .row > div,
.cs-table-head .row > div {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 0.875rem;
}
.cs-table-head .row > div:not(:first-child),
.cs-table-body .row > div:not(:first-child) {
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}
.cs-table-body .row > div a:last-child {
  margin-left: 10px;
}
.cs-table .status {
  background: #008f2b;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  text-transform: uppercase;
}

.section-invoice {
  padding-bottom: 20px;
}

.cp {
  width: 100%;
  padding: 20px 0 0;
}

.curr-subscription {
  background: #b7b700;
  color: #fff;
  position: relative;
  padding-bottom: 27px;
}
.curr-subscription:after {
  content: 'You are on this Subscription';
  position: absolute;
  bottom: 12px;
  width: 100%;
  font: 0.75rem arial;
}

.txt-duration {
  padding: 15px 0;
}
.table-plan-benefits {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
  font-size: 0.875rem;
  min-width: 500px;
}
.bg1 {
}
.bg2 {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.bg3 {
  background: rgba(0, 255, 0, 0.2);
}

.bg1,
.bg2,
.bg3 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 7px 5px;
  height: 100%;
}
.tpl {
  font-weight: bold;
}
.section-updating {
  margin-top: 30px;
}
.section-updating .title {
  font-weight: bold;
}
.section-btn-updating {
  margin: 10px 0 20px;
}
.disclaimer-updating {
  font: 0.75rem arial;
  padding-bottom: 20px;
}

.section-logo-organization {
  text-align: right;
  padding-top: 15px;
}
.organization-logo {
  max-height: 150px;
  border: 1px solid #dadce0;
  display: inline-block;
  min-height: 100px;
  min-width: 50px;
}

.organization-logo-change {
  display: flex;
  justify-content: end;
}
.btn-organization01 {
  border: 1px solid #dadce0;
  display: flex;
  width: 150px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #3583eb;
  font: bold 1rem arial;
  margin-top: 15px;
}
.organization-logo-change svg {
  margin-right: 5px;
}
.btn-organization01:hover {
  background: #f6fafe;
}
.btn-organization01.edit {
  margin-right: 10px;
}
.cs-table-body .btn-organization01 {
  font-size: 0.875rem;
}

.btn-strip {
  /*padding-left: 150px;*/
  display: flex;
}
.organization-logo-change.organization-disable label {
  pointer-events: none;
  cursor: none;
  opacity: 0.5;
  width: auto;
  padding: 0 10px;
}
.btn-organization,
.btn-organization01 {
  height: 36px;
  cursor: pointer;
  text-decoration: none;
}
.btn-organization {
  background: #008f2b;
  color: #ffffff;
  line-height: 36px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  display: inline-block;
  text-decoration: none;
  margin-left: auto;
}
.btn-organization:hover {
  background: #53da00;
}
.btn-organization.disable {
  opacity: 0.5;
}

.organization-strip:not(.f) {
  padding-top: 15px;
}
.organization-label {
  min-width: 150px;
  padding: 9px 0 0;
  max-width: 150px;
}
.organization-field {
  width: 100%;
}
.ac {
  display: flex;
  align-items: center;
}

.page-title .btn-organization {
  font-size: 0.875rem;
  margin-left: auto;
}
.list-new-organization {
  height: 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  padding: 0 10px 0 0;
}
.list-new-organization svg {
  top: 0 !important;
  right: 0 !important;
}
.list-new-organization:hover {
  background: rgba(0, 0, 0, 0.05);
}

.text-right {
  text-align: right;
}

.popup-add-organization {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  max-width: 40%;
  margin: 10vh auto 0;
  padding: 15px;
  border-radius: 5px;
  outline: 0;
}
.pao-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.txt-pao-title {
  color: #008f2b;
  font-weight: bold;
}

._pt15 {
  padding-top: 15px;
}
._pt50 {
  padding-top: 50px;
}
._pb50 {
  padding-bottom: 50px;
}

._m0 {
  margin: 0;
}
._p10 {
  padding: 10px;
}
._mr10 {
  margin-right: 10px;
}
._ml10 {
  margin-left: 10px;
}
._mb20 {
  margin-bottom: 20px;
}

.justify-content-left {
  justify-content: left !important;
}
.width-auto {
  width: auto !important;
}
.select-color-root {
  margin-left: 5px;
  position: relative;
  width: 55px;
  height: 40px;
}
.select-color-box {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.sketchpicker-bg-div {
  position: absolute;
  z-index: 2;
  top: 50px;
  right: 0px;
}
.sketchpicker-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.span-color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.color-simple-select .MuiSelect-selectMenu {
  min-height: auto;
  height: 100%;
  padding: 10px;
  padding-left: 0;
  padding-right: 24px;
  margin-top: 1px;
}
.color-simple-select .MuiSelect-nativeInput {
  padding: 10px;
  margin: 0px;
  cursor: inherit;
  background-color: white;
  font-size: 14px;
  border: 1px solid rgb(204, 204, 204);
  outline: 0px;
  width: 100%;
  opacity: 1;
}
.color-simple-select .MuiInput-root {
  width: 100%;
}
.color-simple-select {
  width: 80%;
}
.organization-strip {
  padding-top: 15px;
}
.cs-table-parent {
  overflow: auto;
}
.organization-logo-change .btn-organization01 {
  height: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;
}
.organization-logo-change label.btn-organization01 {
  padding: 12px 10px;
}
.page-title {
  flex-wrap: wrap;
}
.btn-subscription-parent {
  flex-wrap: wrap;
}
.overflow-auto {
  overflow: auto;
}
.flex-wrap-wrap {
  flex-wrap: wrap;
}

@media (min-width: 1000px) {
  .publisher-verifier-slider .slick-track {
    width: 99% !important;
  }
  .publisher-verifier-slider .slick-slide {
    width: max-content !important;
  }

  .publisher-verifier-slider .slick-slide:not(:last-child) {
    margin-right: 30px;
  }
  .publisher-verifier-slider .slick-slide {
    margin: 10px 0px 10px 0;
  }
}
.full-package-slider .slick-list {
  border: 1px solid #e4e4e4;
}
@media (min-width: 768px) {
  .full-package-slider .slick-track {
    padding: 10px 0;
    /* border: 1px solid; */
  }

  /* .full-package-slider .slick-slide{
    width: max-content !important;
  }

  .full-package-slider .slick-slide:not(:last-child){
    margin-right: 30px;
  }
  .full-package-slider .slick-slide{
    margin: 10px 0px 10px 0;
  } */
}

@media (max-width: 768px) {
  .strip-tc {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .item-sc {
    margin: 20px;
  }
  .subscription-carousel {
    padding: 20px 0 !important;
  }
  .subscription-carousel > h2,
  .subscription-carousel > div:nth-child(2) {
    padding: 0px 20px !important;
  }
  .subscription-carousel > h2 {
    margin-bottom: 20px;
  }
  .subscription-carousel > hr {
    margin: 0 20px 20px 20px;
    width: calc(100% - 40px);
  }
  .subscription-carousel .slick-list {
    margin: 0 !important;
  }
  .subscription-carousel .slick-initialized .slick-slide {
    padding: 10px;
  }
}

@media (max-width: 600px) {
  .field-update-password {
    flex-wrap: wrap;
  }
  .s_title {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .s_title > span {
    margin-top: 10px;
  }
}
@media (max-width: 480px) {
  .organization-strip {
    flex-wrap: wrap;
  }
  .profile-heading .subscription-title {
    width: 100%;
  }
  .profile-heading {
    flex-wrap: wrap;
  }
  .profile-heading a.btn-subscription {
    margin-left: auto;
  }
  .item-sc {
    margin: 10px;
  }
}
