.csv-field{width: 100%;}
.csv-label{min-width: 50px; padding: 6px 0 0; max-width: 150px;}
.csv-strip:not(.f){padding-top: 15px;}
.csv-strip { padding-top: 15px; }
.justify-content-left{justify-content: left !important;}
.width-auto{width: auto !important;}
.btn-csv { background: #008f2b; color: #ffffff; line-height: 36px; padding: 0 20px; border-radius: 5px; margin-top: 0px; display: inline-block; text-decoration: none; margin-left: 15px; height: 36px; cursor: pointer; }
.btn-csv:hover { background: #53da00; }
.section-csv { padding-top: 0px; display: flex; justify-content: end; }
.csv-file { padding: 0px 10px; height: auto; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; width: auto; max-width: 100%; cursor: pointer; }
.csv-file { border: 1px solid #dadce0; display: flex; width: 150px; border-radius: 4px; align-items: center; justify-content: center; color: #3583eb; font: bold 1rem arial; margin-top: 0px; }

@media (max-width: 480px){
    .csv-strip { flex-wrap: wrap; }
}