:root{
    --greenColor:#008F2B;
    --whiteColor: #fff;
    --color888: #888;
    --borderColor: rgba(0, 0, 0, 0.1);
}
/* Navbar CSS */
.navbar-root-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-mobile-right-side{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.dropdown-item-selected{
    background-color: #008F2B40;
    color: #008F2B !important;
}

.dropdown-item-selected:hover{
    background-color: #008F2B40 !important;
    color: #008F2B !important;
}

.navbar-menu-icon{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.navbar-menu-icon svg{
    width: 30px;
    height: 30px;
}

.navbar-ul-root>ul>li{
    padding: 15px 0px;
}

/* Home page body CSS */
.home-page-heading-search-div{
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
}
.home-page-product-info-style{
    width: calc(100% - 60px);
}
.home-page-product-list-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.home-page-product-list-heading>div:first-child{
    width: calc(100% - 100px);
}
.home-page-product-title {
    white-space: unset !important;
    text-overflow: ellipsis;
    word-break:break-all;
    margin: 0;
    cursor: pointer;
}
.home-page-product-description{
    cursor: pointer;
    word-break:break-all;
    display: block;
}
.home-page-product-list-heading-icons {
    display: flex;
    align-items: center;
    margin-right: 30px;
}
.home-page-product-list-heading-icons>div{
    margin-left: 10px;
}
.product-offers-icons{
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-offers-icons>div:nth-child(2){
    margin-left: 10px;
}
.home-page-product-switch>span{
    padding: 0;
    display: flex;
    width: 28px;
    height: 16px;
}
.home-page-product-switch>span>span:nth-child(1){
    padding: 2px;
    color: var(--whiteColor);
}
.home-page-product-switch>span>span:nth-child(1)>span:nth-child(1)>span{
    width: 12px;
    height: 12px;
}

.home-page-product-switch>span>span:nth-child(2){
    border-radius: 8px;
}
.home-page-product-switch .checked>span:nth-child(1) {
    transform: translateX(12px);
}
.home-page-product-switch .checked>span:nth-child(2) {
    background-color: var(--greenColor);
    opacity: 1;
}
.home-page-product-switch .disabled{
    opacity: 0.5;
    cursor: initial;
}
.home-page-product-ingredient-dialog-loader{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-page-product-ingredient-dialog-loader>div{
    color: var(--greenColor);
}
.product-ingredients-ul{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.product-ingredients-li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.product-ingredients-li>div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.product-ingredients-li>div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 60px);
}
.product-ingredients-dialog-heading{
    border-bottom: 1px solid var(--borderColor);
}
.product-ingredients-dialog-footer {
    border-top: 1px solid var(--borderColor);
    padding: 16px 24px !important;
}
.product-ingredients-dialog-footer>button{
    background-color: var(--greenColor);
    color: var(--whiteColor)
}
.product-ingredients-dialog-footer>button:hover{
    background-color: var(--greenColor);
    opacity: 0.8;
}

/* Home page right side batch create or update section CSS */
.homepage-rightside-close-icon{
    display: none;
}

.createIntegrantTypePane{
    overflow: auto;
    margin-bottom: 60px !important;
}

.cloneTransferIn-div{
    min-width: 700px;
    width: 100%;
}

/* Product create Form CSS */
.integrant-type-image-label{
    padding: 0 10px;
    cursor: pointer;
    color: var(--color888);
    margin: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
}
.integrant-type-image-label:nth-child(1){
    width: calc(100% - 70px);
}
.integrant-type-image-label>div:nth-child(2){
    width: 60px;
    height: 60px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.integrant-type-form-root{
    position: relative;
}
.integrant-type-form-loader{
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    background-color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    pointer-events: none;
}
.integrant-type-form-loader>div{
    color: var(--greenColor);
}
.batch-list-action-buttons{
    margin-top: 10px;
}
.batch-list-action-buttons>a{
    margin-right: 10px;
}

@media (max-width:992px) {
    .home-page-heading-search-div{
        max-width: 100%;
    }
}

@media (max-width:768px) {
    .homepage-rightside-close-icon{
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
    .cloneTransferIn-div{
        min-width: 450px;
    }
}

@media (max-width:500px) {
    /* Navbar CSS */
    .navbar-root-div{
        align-items: flex-start;
    }
    .navbar-ul-root .navbar-nav .nav-link{
        justify-content: flex-end;
        border-left: none;
    }

    .navbar-ul-root .navbar-nav li.nav-item.last .nav-link{
        padding-right: 15px;
    }

    .navbar-ul-root .navbar-nav .dropdown-menu{
        top: 45px;
        z-index: 2;
    }

    .navbar-ul-root>ul{
        display: flex;
        flex-direction: column;
    }
    .cloneTransferIn-div{
        min-width: unset;
    }
}