:root{
    --greenColor:#0eca5a
}

.header-notification-icon svg{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.header-notification-icon>span>span{
    background-color: var(--greenColor);
}